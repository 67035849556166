import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent implements OnInit {

  constructor() { }

    ngOnInit() {
        var line = document.getElementById('line');
        var chart = new Chart(line, {
            type: 'line',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                datasets: [{
                pointBorderWidth: 1,
                pointRadius: 5,
                pointHoverRadius: 6,
                pointBackgroundColor: '#fff',
                backgroundColor: 'rgba(0, 0, 0, 0.0)',
                borderColor: 'rgb(241, 135, 142)',
                borderWidth: 2,
                data: [20, 15, 3, 12, 8, 19]
                }]
            },

            options: {
                responsive:true,
                legend: {
                    display: false,
                    },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'rgba(255, 255, 255, 0.5)'
                        },
                        ticks: {
                            fontColor: "rgba(255, 255, 255, 0.5)",
                        },
                        }],
                    yAxes: [{
                        gridLines: {
                        color: 'rgba(255, 255, 255, 0.5)'
                        },
                        ticks: {
                        fontColor: "rgba(255, 255, 255, 0.5)",
                        },
                    }]
                }
            }
        });
    }
}
