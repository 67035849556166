import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-doughtnut',
  templateUrl: './doughtnut.component.html',
  styleUrls: ['./doughtnut.component.scss']
})
export class DoughtnutComponent implements OnInit {

  constructor() { }

    ngOnInit() {
        var doughnut = document.getElementById('doughnut');
        var chart = new Chart(doughnut, {
            type: 'doughnut',
            data: {
                labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
                datasets: [{
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgb(1, 123, 125)',
                    'rgb(146, 188, 188)',
                    'rgb(222 ,66, 91)',
                    'rgb(241, 135, 142)',
                    'rgb(252, 196, 197)',
                    'rgb(181, 189, 0)'
                ],
                borderColor: [
                    'rgb(1, 123, 125)',
                    'rgb(146, 188, 188)',
                    'rgb(222 ,66, 91)',
                    'rgb(241, 135, 142)',
                    'rgb(252, 196, 197)',
                    'rgb(181, 189, 0)'
                ],
                borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                display: true,
                position: 'right',

                labels: {
                    padding: 10,
                }
                }
            }
        });
    }
}
