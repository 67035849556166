import { Component } from '@angular/core';
import { Chart } from 'chart.js';
// import { DoughtnutComponent } from './components/charts/doughtnut/doughtnut.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aitsl-ite';

// chart object
  doughnut = []; // This will hold our chart info

    ngOnInit() {

        // // DOUGHNUT
        // var doughnut = document.getElementById('doughnut');
        // var chart = new Chart(doughnut, {
        //     type: 'doughnut',
        //     data: {
        //         labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
        //         datasets: [{
        //         data: [12, 19, 3, 5, 2, 3],
        //         backgroundColor: [
        //             'rgb(1, 123, 125)',
        //             'rgb(146, 188, 188)',
        //             'rgb(222 ,66, 91)',
        //             'rgb(241, 135, 142)',
        //             'rgb(252, 196, 197)',
        //             'rgb(181, 189, 0)'
        //         ],
        //         borderColor: [
        //             'rgb(1, 123, 125)',
        //             'rgb(146, 188, 188)',
        //             'rgb(222 ,66, 91)',
        //             'rgb(241, 135, 142)',
        //             'rgb(252, 196, 197)',
        //             'rgb(181, 189, 0)'
        //         ],
        //         borderWidth: 1,
        //         }]
        //     },
        //     options: {
        //         responsive: true,
        //         legend: {
        //             display: true,
        //             position: 'right',

        //             labels: {
        //                 padding: 10,
        //             }
        //         }
        //     }
        // });


        //  DOUGHNUT ON TRANSPARENT BACKGROUND
        var doughnutOnTransparentBg = document.getElementById('doughnut-on-transparent-bg');
        var chart = new Chart(doughnutOnTransparentBg, {
            type: 'doughnut',
            data: {
                labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
                datasets: [{
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgb(1, 123, 125)',
                    'rgb(146, 188, 188)',
                    'rgb(222 ,66, 91)',
                    'rgb(241, 135, 142)',
                    'rgb(252, 196, 197)',
                    'rgb(181, 189, 0)'
                ],
                borderColor: [
                    'rgb(1, 123, 125)',
                    'rgb(146, 188, 188)',
                    'rgb(222 ,66, 91)',
                    'rgb(241, 135, 142)',
                    'rgb(252, 196, 197)',
                    'rgb(181, 189, 0)'
                ],
                borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: true,
                    position: 'right',

                    labels: {
                        padding: 10,
                        fontColor: '#fff'
                    }
                }
            }
        });




        // LINE
        var line = document.getElementById('line');
        var chart = new Chart(line, {
        type: 'line',
        data: {
            labels: ['January', 'February', 'March', 'April', 'May', 'June'],
            datasets: [{
                pointBorderWidth: 1,
                pointRadius: 5,
                pointHoverRadius: 6,
                pointBackgroundColor: '#fff',
                backgroundColor: 'rgba(0, 0, 0, 0.0)',
                borderColor: 'rgb(241, 135, 142)',
                borderWidth: 2,
                data: [20, 15, 3, 12, 8, 19]
            }]
        },
    
        options: {
            responsive:true,
            legend: {
            display: false,
            },
                scales: {
                xAxes: [{
                    gridLines: {
                        color: 'rgba(255, 255, 255, 0.5)'
                    },
                    ticks: {
                        fontColor: "rgba(255, 255, 255, 0.5)",
                    },
                    }],
                yAxes: [{
                    gridLines: {
                    color: 'rgba(255, 255, 255, 0.5)'
                    },
                    ticks: {
                    fontColor: "rgba(255, 255, 255, 0.5)",
                    },
                }]
                }
            }
        });


        // LINE ON WHITE BACKGROUND
        var lineOnWhiteBg = document.getElementById('line-on-white-bg');
        var chart = new Chart(lineOnWhiteBg, {
            type: 'line',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                datasets: [{
                    pointRadius: 5,
                    pointHoverRadius: 6,
                    pointBackgroundColor: 'rgb(241, 135, 142)',
                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                    borderColor: 'rgb(241, 135, 142)',
                    borderWidth: 2,
                    data: [20, 15, 3, 12, 8, 19]
                }]
        },
    
            options: {
                responsive:true,
                legend: {
                    display: false,
                }
            }
        });


        // BAR
        var bar = document.getElementById('bar');
        var chart = new Chart(bar, {
            type: 'bar',
            data: {
                labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
                datasets: [{
                    data: [12, 19, 3, 5, 7, 3],
                    backgroundColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                // aspectRatio: 4,
                // onResize: function () {
                    // if ($(window).width() < 768) {
                        // console.log('Less than 768');
                        // bar.canvas.parentNode.style.height = '300px';   // IN ANGULAR THIS DOESN'T WORK
                        // bar.update();                                   // IN ANGULAR THIS DOESN'T WORK
                    // }
                    // else {
                        // console.log('More than 768');
                        // bar.canvas.parentNode.style.height = '600px';   // IN ANGULAR THIS DOESN'T WORK
                        // bar.update();                                   // IN ANGULAR THIS DOESN'T WORK
                    // }             
                legend: {
                    display: false,
                }
            }
        });

        // BAR WITH TRANSPARENT BACKGROUND
        var barOnTransparentBg = document.getElementById('bar-on-transparent-bg');
        var chart = new Chart(barOnTransparentBg, {
            type: 'bar',
            data: {
                labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
                datasets: [{
                    data: [12, 19, 8, 15, 7, 3],
                    backgroundColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive:true,
                maintainAspectRatio: false,
                onResize: function () {
                    if ($(window).width() < 768) {
                        console.log('Less than 768');
                        // barOnTransparentBg.canvas.parentNode.style.height = '300px';   // IN ANGULAR THIS DOESN'T WORK
                    }
                    else {
                        console.log('More than 768');
                        // barOnTransparentBg.canvas.parentNode.style.height = '600px';   // IN ANGULAR THIS DOESN'T WORK
                    }             
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display:false
                        },
                        ticks: {
                            fontColor: "rgba(255, 255, 255, 0.5)",
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            // display:false,
                            color: 'rgba(255, 255, 255, 0.5)'
                        },
                        ticks: {
                            fontColor: "rgba(255, 255, 255, 0.5)",
                        },
                    }]
                }
            }
        });

        //  HORIZONTAL BAR
        var horizontalBar = document.getElementById('horizontal-bar');
        var chart = new Chart(horizontalBar, {
            type: 'horizontalBar',
            data: {
                labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
                datasets: [{
                    data: [12, 19, 3, 5, 7, 3],
                    backgroundColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive:true,
                legend: {
                    display: false,
                }
            }
        });

        //  HORIZONTAL BAR WITH TRANSPARENT BACKGROUND
        var horizontalBarOnTransparentBg = document.getElementById('horizontal-bar-on-transparent-bg');
        var chart = new Chart(horizontalBarOnTransparentBg, {
            type: 'horizontalBar',
            data: {
                labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
                datasets: [{
                    data: [12, 19, 3, 5, 7, 3],
                    backgroundColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive:true,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'rgba(255, 255, 255, 0.5)'
                        },
                        ticks: {
                            fontColor: "rgba(255, 255, 255, 0.5)",
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            display:false,
                        },
                        ticks: {
                            fontColor: "rgba(255, 255, 255, 0.5)",
                        },
                    }]
                }
            }
        });

        //  PIE
        var pie = document.getElementById('pie');
        var chart = new Chart(pie, {
            type: 'pie',
            data: {
                labels: ['Petrol', 'LightPetrol', 'RedPunch', 'Peach', 'Salmon', 'Lime'],
                color: '#fff',
                datasets: [{
                    // label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive:true,
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        padding: 10,
                        fontColor: '#fff'
                    },
                }
            }
        });

        //  PIE ON WHITE BACKGROUND
        var pieOnWhiteBg = document.getElementById('pie-on-white-bg');
        var chart = new Chart(pieOnWhiteBg, {
            type: 'pie',
            data: {
                labels: ['Petrol', 'LightPetrol', 'RedPunch', 'Peach', 'Salmon', 'Lime'],
                // color: '#fff',
                datasets: [{
                    label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive:true,
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        padding: 10,
                        // fontColor: '#fff'
                    },
                }
            }
        });
    }
}
