import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { IntroPopComponent } from './components/intro-pop/intro-pop.component';
import { FilterComponent } from './components/filter/filter.component';
import { DoughtnutComponent } from './components/charts/doughtnut/doughtnut.component';
import { LineComponent } from './components/charts/line/line.component';
import { BarComponent } from './components/charts/bar/bar.component';
import { PieComponent } from './components/charts/pie/pie.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    IntroPopComponent,
    FilterComponent,
    DoughtnutComponent,
    LineComponent,
    BarComponent,
    PieComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue: '/'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
