import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {

  constructor() { }

    ngOnInit() {
        var bar = document.getElementById('bar');
        var chart = new Chart(bar, {
            type: 'bar',
            data: {
                labels: ['Petrol', 'LightPetrol', 'Red Punch', 'Peach', 'Salmon', 'Lime'],
                datasets: [{
                    data: [12, 19, 3, 5, 7, 3],
                    backgroundColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderColor: [
                        'rgb(1, 123, 125)',
                        'rgb(146, 188, 188)',
                        'rgb(222 ,66, 91)',
                        'rgb(241, 135, 142)',
                        'rgb(252, 196, 197)',
                        'rgb(181, 189, 0)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                // aspectRatio: 4,
                // onResize: function () {
                    // if ($(window).width() < 768) {
                        // console.log('Less than 768');
                        // bar.canvas.parentNode.style.height = '300px';   // IN ANGULAR THIS DOESN'T WORK
                        // bar.update();                                   // IN ANGULAR THIS DOESN'T WORK
                    // }
                    // else {
                        // console.log('More than 768');
                        // bar.canvas.parentNode.style.height = '600px';   // IN ANGULAR THIS DOESN'T WORK
                        // bar.update();                                   // IN ANGULAR THIS DOESN'T WORK
                    // }             
                legend: {
                    display: false,
                }
            }
        });
    }
}
